//listen to resize events
window.addEventListener('message', function (e) {
    var iframe = document.getElementById("UnsereProdukteIFrame");
    var iframeDimensions = e.data; // message that was passed from iframe page
    iframe.style.height = iframeDimensions.height;
    iframe.style.width = iframeDimensions.width;
    // Check if the message is being sent by the usual propagateHeight or by the alternative.
    // This is to avoid scrolling to the top of the page when expanding a subcategory in the mobile category menu
    if (iframeDimensions.resetScroll) {
        setTimeout(function () {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 250);
    }
}, false);
//send scroll position to iframe
document.addEventListener("scroll", function () {
    var iframe = document.getElementById("UnsereProdukteIFrame");
    var navBarOffset = 0;
    if (document.querySelector('.header-top') && document.querySelector('.header-bottom')) {
        navBarOffset = document.querySelector('.header-top').clientHeight + document.querySelector('.header-bottom').clientHeight + 1;
    }
    var scrollPosition = Math.abs(iframe.getBoundingClientRect().top) + navBarOffset;
    iframe.contentWindow.postMessage(scrollPosition, "*");
});
